import { AwsServices } from 'src/app/services/aws.services';

import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './gpratepro.component.html',
})
export class GPRatePro implements OnInit {
  constructor(private awsServices: AwsServices) {}

  ngOnInit() {
    this.awsServices.getGpRatePro().subscribe((res: string) => {
      window.open(res);
    });
  }
}
