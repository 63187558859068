import { EStoresActions, StoriesActions } from '../actions/stores.actions';
import { IStoresState, initialStoresState } from '../state/stores.state';

export function storesReducer(
  state = initialStoresState,
  action: StoriesActions,
): IStoresState {
  switch (action.type) {
    case EStoresActions.GetStoreSuccess: {
      return {
        ...state,
        store: action.payload,
      };
    }

    case EStoresActions.GetStoresSuccess: {
      return {
        ...state,
        stores: action.payload,
      };
    }

    default:
      return state;
  }
}
