import { Action } from '@ngrx/store';

export enum EUserActions {
  GetUser = '[User] Get User',
  GetUserSuccess = '[User] Get User Success',
  UpdateUser = '[User] Update User',
  UpdateUserSuccess = '[User] Update User Success',
}

export class GetUser implements Action {
  public readonly type = EUserActions.GetUser;
}

export class GetUserSuccess implements Action {
  public readonly type = EUserActions.GetUserSuccess;
  constructor(public payload: any) {}
}

export class UpdateUser implements Action {
  public readonly type = EUserActions.UpdateUser;
  constructor(public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  public readonly type = EUserActions.UpdateUserSuccess;
  constructor(public payload: any) {}
}

export type UserActions =
  | GetUser
  | GetUserSuccess
  | UpdateUser
  | UpdateUserSuccess;
