import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  ApplicationConfig,
  inject,
} from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';

import { provideAppRouting } from './app-routing.module';
import { registerMaterialIcons } from './icons';
import { bearerTokenInterceptor } from './interceptors/token.interceptor';
import { LCP$ } from './shared/utils/lcp';
import { AuthEffects } from './state/effects/auth.effects';
import { appReducers } from './state/reducers/app.reducers';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_ID, useValue: 'goinpostal' },

    provideClientHydration(),

    provideHttpClient(withInterceptors([bearerTokenInterceptor])),

    provideAppRouting(),

    provideStore(appReducers),
    provideEffects([AuthEffects]),
    provideRouterStore(),

    registerMaterialIcons(),

    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        // Inject here explicitly to avoid late event listener setup.
        inject(LCP$);
        // `APP_INITIALIZER` is 'chunked' in this case to prevent frame drop
        // during the initial rendering.
        return () => new Promise(resolve => setTimeout(resolve));
      },
    },
  ],
};
