import { InjectionToken, NgZone, inject } from '@angular/core';
import { Observable, ReplaySubject, fromEvent, of, take } from 'rxjs';

export const LCP$ = new InjectionToken<Observable<void>>(
  'Largest Contentful Paint',
  {
    providedIn: 'root',
    factory: () => {
      // Note: do not use `isPlatformServer` because it's not tree-shakable.
      if (global_isServer) {
        return of(undefined);
      }

      const ngZone = inject(NgZone);
      const lcp$ = new ReplaySubject<void>(1);

      // When we attach an event listener to the window `load` event, the code
      // within that event listener should run after the LCP is done. However,
      // please note that there might be exceptions or edge cases where the `load`
      // event fires before the LCP is complete, especially if there are long-loading
      // resources or scripts that block the page's rendering.
      ngZone.runOutsideAngular(() =>
        fromEvent(window, 'load')
          .pipe(take(1))
          .subscribe(() => {
            lcp$.next();
            lcp$.complete();
          }),
      );

      return lcp$;
    },
  },
);
