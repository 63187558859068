<footer class="footer">
  <div class="contentWrapper">
    <div class="footerNav">
      <div class="footerNav__item">
        <a class="gray" [routerLink]="['/locations']">LOCATIONS</a>
      </div>

      <div class="footerNav__item">
        <a class="gray" [routerLink]="['/services']">SERVICES</a>
      </div>

      <div class="footerNav__item">
        <a class="gray" [routerLink]="['/about-us']">ABOUT US</a>
      </div>

      <div class="footerNav__item">
        <a class="gray" [routerLink]="['/franchise-info']">FRANCHISE INFO</a>
      </div>

      <div class="footerNav__item">
        <a role="button" tabindex="0" class="gray" (click)="login()">LOGIN</a>
      </div>
    </div>

    <div class="contacts">
      <div class="h3">corporate office</div>

      <div class="contact_item">
        <a
          target="_blank"
          href="https://www.google.com/maps/place/GP+Brands/@28.2298593,-82.1834185,15z/data=!4m2!3m1!1s0x0:0x787cbecf9ec90d75?sa=X&ved=2ahUKEwit5_3ElojoAhVUwMQBHaGsCdoQ_BIwBXoECBUQCA"
          class="location"
        >
          4941 4th Street, Zephyrhills, FL 33542
        </a>
      </div>

      <div class="contact_item">
        <span>General Info</span>
        <a href="mailto:info@goinpostal.com"> info@goinpostal.com </a>
      </div>

      <div class="contact_item">
        <span>phone</span>
        <a href="tel:813-782-1500">813-782-1500</a>
      </div>

      <div class="contact_item">
        <span>fax</span>
        <a href="tel:813-782-1599">813-782-1599</a>
      </div>
    </div>

    <div class="separator"></div>

    <div class="franshiseLine">
      <div class="info">
        <div class="h3">Franchise Line</div>
        <a href="tel:800 504 6040">800 504 6040</a>
      </div>
    </div>
  </div>
</footer>
