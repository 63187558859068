import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AwsServices {
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  public getPrintAds(fileName: string) {
    return this.http.get<[]>(`${this.apiUrl}print-ads/${fileName}`);
  }

  public getPrintAd(fileId: number) {
    return this.http.get(`${this.apiUrl}print-ad/${fileId}`);
  }

  public getFile(fileName: string) {
    return this.http.get(`${this.apiUrl}download`, {
      params: { file: fileName },
    });
  }

  public getGpRatePro() {
    return this.http.get(`${this.apiUrl}gpratepro`);
  }

  public getStoreImages(storeId) {
    return this.http.get(`${this.apiUrl}store/${storeId}`);
  }

  public getLogoImages(imageKey) {
    return this.http.get(`${this.apiUrl}resources/image`, {
      params: { image: imageKey },
    });
  }

  public getStoreFiles(storeOldId: string) {
    return this.http.get(`${this.apiUrl}stores/files`, {
      params: {
        storeOldId,
      },
    });
  }
}
