import { AuthActions, EAuthActions } from '../actions/auth.actions';
import { IAuthState, initialAuthState } from '../state/auth.state';

// import {InitialAuthState, IAuthState} from "../state/auth.state";

export function authReducer(
  state = initialAuthState,
  action: AuthActions,
): IAuthState {
  switch (action.type) {
    // case EAuthActions.RefreshToken: {
    //     return {
    //       ...state,
    //       latestRequest: action.payload
    //     };
    // }

    case EAuthActions.SignIn: {
      return {
        ...state,
        loading: true,
      };
    }

    case (EAuthActions.SignInSuccess, EAuthActions.SignInFailure): {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
