import {
  APP_INITIALIZER,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import addShoppingCartIcon from '@material-design-icons/svg/filled/add_shopping_cart.svg';
import arrowForwardIosIcon from '@material-design-icons/svg/filled/arrow_forward_ios.svg';
import callIcon from '@material-design-icons/svg/filled/call.svg';
import closeIcon from '@material-design-icons/svg/filled/close.svg';
import creditCardIcon from '@material-design-icons/svg/filled/credit_card.svg';
import descriptionIcon from '@material-design-icons/svg/filled/description.svg';
import emailIcon from '@material-design-icons/svg/filled/email.svg';
import faceIcon from '@material-design-icons/svg/filled/face.svg';
import faxIcon from '@material-design-icons/svg/filled/fax.svg';
import infoIcon from '@material-design-icons/svg/filled/info.svg';
import invertColorsIcon from '@material-design-icons/svg/filled/invert_colors.svg';
import locationOnIcon from '@material-design-icons/svg/filled/location_on.svg';
import loginIcon from '@material-design-icons/svg/filled/login.svg';
import mapIcon from '@material-design-icons/svg/filled/map.svg';
import miscellaneousServicesIcon from '@material-design-icons/svg/filled/miscellaneous_services.svg';
import navigateBeforeIcon from '@material-design-icons/svg/filled/navigate_before.svg';
import navigateNextIcon from '@material-design-icons/svg/filled/navigate_next.svg';
import permMediaIcon from '@material-design-icons/svg/filled/perm_media.svg';
import pushPinIcon from '@material-design-icons/svg/filled/push_pin.svg';
import roomIcon from '@material-design-icons/svg/filled/room.svg';
import scheduleIcon from '@material-design-icons/svg/filled/schedule.svg';
import searchIcon from '@material-design-icons/svg/filled/search.svg';
import shareIcon from '@material-design-icons/svg/filled/share.svg';
import shoppingCartIcon from '@material-design-icons/svg/filled/shopping_cart.svg';
import straightenIcon from '@material-design-icons/svg/filled/straighten.svg';
import todayIcon from '@material-design-icons/svg/filled/today.svg';

interface Icon {
  name: string;
  svg: string;
}

const getIconsList = (): Icon[] => [
  {
    name: 'search',
    svg: searchIcon,
  },
  {
    name: 'credit_card',
    svg: creditCardIcon,
  },
  {
    name: 'shopping_cart',
    svg: shoppingCartIcon,
  },
  {
    name: 'description',
    svg: descriptionIcon,
  },
  {
    name: 'location_on',
    svg: locationOnIcon,
  },
  {
    name: 'perm_media',
    svg: permMediaIcon,
  },
  {
    name: 'login',
    svg: loginIcon,
  },
  {
    name: 'close',
    svg: closeIcon,
  },
  {
    name: 'invert_colors',
    svg: invertColorsIcon,
  },
  {
    name: 'straighten',
    svg: straightenIcon,
  },
  {
    name: 'push_pin',
    svg: pushPinIcon,
  },
  {
    name: 'face',
    svg: faceIcon,
  },
  {
    name: 'info',
    svg: infoIcon,
  },
  {
    name: 'add_shopping_cart',
    svg: addShoppingCartIcon,
  },
  {
    name: 'arrow_forward_ios',
    svg: arrowForwardIosIcon,
  },
  {
    name: 'room',
    svg: roomIcon,
  },
  {
    name: 'call',
    svg: callIcon,
  },
  {
    name: 'email',
    svg: emailIcon,
  },
  {
    name: 'schedule',
    svg: scheduleIcon,
  },
  {
    name: 'navigate_before',
    svg: navigateBeforeIcon,
  },
  {
    name: 'navigate_next',
    svg: navigateNextIcon,
  },
  {
    name: 'fax',
    svg: faxIcon,
  },
  {
    name: 'map',
    svg: mapIcon,
  },
  {
    name: 'miscellaneous_services',
    svg: miscellaneousServicesIcon,
  },
  {
    name: 'share',
    svg: shareIcon,
  },
  {
    name: 'today',
    svg: todayIcon,
  },
];

export function registerMaterialIcons() {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const sanitizer = inject(DomSanitizer);
      const registry = inject(MatIconRegistry);
      const transferState = inject(TransferState);
      const iconsKey = makeStateKey<Icon[]>('icons');

      const addIcons = (icons: Icon[]) =>
        icons.forEach(({ name, svg }) => {
          registry.addSvgIconLiteral(
            name,
            sanitizer.bypassSecurityTrustHtml(svg),
          );
        });

      return () => {
        // When doing server-side rendering we register those icons and also
        // save it onto the transfer state so it's sent to the client.
        if (global_isServer) {
          const icons = getIconsList();
          addIcons(icons);
          transferState.set(iconsKey, icons);
        }

        // We don't have to call `getIconsList` in the browser since we transfer
        // those icons above. This is done to avoid duplication the code between
        // the server and the client.
        if (global_isBrowser) {
          const icons = transferState.get(iconsKey, []);
          addIcons(icons);
        }
      };
    },
  };
}
