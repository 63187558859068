import { EUserActions, UserActions } from '../actions/user.actions';
import { IUserState, initialUserState } from '../state/user.state';

export function userReducer(
  state = initialUserState,
  action: UserActions,
): IUserState {
  switch (action.type) {
    case EUserActions.GetUserSuccess: {
      return {
        ...state,
        user: action.payload,
      };
    }

    default:
      return state;
  }
}
