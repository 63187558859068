import { Component, Injector, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import { loadAuthPopupComponent } from '../auth/auth.config';
import { getMatDialog } from '../utils/load-mat-dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink],
})
export class FooterComponent {
  private readonly injector = inject(Injector);

  async login() {
    const dialog = await getMatDialog(this.injector);
    const AuthPopupComponent = await loadAuthPopupComponent();

    dialog.open(AuthPopupComponent, {
      hasBackdrop: true,
      closeOnNavigation: true,
      width: '32rem',
      height: '40rem',
    });
  }
}
