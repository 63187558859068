import { NgIf } from '@angular/common';
import { Component, Injector, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { Router, RouterLink } from '@angular/router';

import { loadAuthPopupComponent } from '../auth/auth.config';
import { getMatDialog } from '../utils/load-mat-dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,

    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
  ],
})
export class HeaderComponent implements OnInit {
  trackingId: string;
  logged: string;
  public findNearestForm: FormGroup;

  private readonly injector = inject(Injector);

  constructor(
    private _router: Router, // private _matTab: MatTab,
  ) {}

  ngOnInit() {
    this.logged = global_isBrowser && localStorage.getItem('accessToken');
    this.findNearestForm = new FormGroup({
      location: new FormControl('1000000'),
    });
  }

  trackPackage(id: any) {
    // console.log('trackPackage id', id);
    this._router.navigateByUrl(`/tracking/${id}`);
  }

  changeDistance(form: FormGroup) {
    this._router.navigateByUrl('/nearest/' + form.value.location);
    console.log(form, 'form');
  }

  async login() {
    const dialog = await getMatDialog(this.injector);
    const AuthPopupComponent = await loadAuthPopupComponent();

    dialog.open(AuthPopupComponent, {
      hasBackdrop: true,
      closeOnNavigation: true,
      width: '32rem',
      height: '40rem',
    });
  }
}
