import { Routes } from '@angular/router';

// Components
import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public.component';
import { SupportComponent } from './support/support.component';

export const publicRoutes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'locations',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'locations' */ './locations/locations.component'
          ).then(m => m.LocationsComponent),
      },
      {
        path: 'services',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'services' */ './services/services.component'
          ).then(m => m.ServicesComponent),
      },
      {
        path: 'about-us',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'about-us' */ './about-us/about-us.component'
          ).then(m => m.AboutUsComponent),
      },
      {
        path: 'franchise-info',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'franchise-info' */ './franchise-info/franchise-info.component'
          ).then(m => m.FranchiseInfoComponent),
      },
      {
        path: 'tracking/:trackingId',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'tracking' */ './trackings/tracking.component'
          ).then(m => m.TrackingComponent),
      },
      {
        path: 'fdd',
        loadComponent: () =>
          import(/* webpackChunkName: 'fdd' */ './fdd/fdd.component').then(
            m => m.FddComponent,
          ),
      },
      {
        path: 'stores/:state',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'stores' */ './stores/stores.component'
          ).then(m => m.StoresComponent),
        data: {
          // `86400` is 1 day in seconds.
          revalidate: 86400,
        },
      },
      {
        path: 'nearest/:distance',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'neares-stores' */ './stores-nearest/stores.component'
          ).then(m => m.NearestStoresComponent),
      },
      {
        path: 'store/:storeId',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'store' */ './store/store.component'
          ).then(m => m.StoreComponent),
        data: {
          // `86400` is 1 day in seconds.
          revalidate: 86400,
        },
      },
      {
        path: 'restor-password',
        loadComponent: () =>
          import(
            /* webpackChunkName: 'restore-password' */ '../shared/restore-password/restore-password.component'
          ).then(m => m.RestorePasswordComponent),
      },
      {
        path: 'support',
        component: SupportComponent,
      },
    ],
  },
];
