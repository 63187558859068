import { Injector, NgModuleRef, createNgModule } from '@angular/core';
import type { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

/**
 * This is a helper function designed to prevent the `legacy-dialog`
 * package from being imported into the main bundle. The dialog package
 * is only required when a dialog needs to be opened; therefore, we will
 * lazy load it when the user initiates the action that triggers the
 * opening of a dialog.
 *
 * Given the following example:
 * ```ts
 * class SomeComponent {
 *   private readonly injector = inject(Injector);
 *
 *   async openSomeDialog() {
 *     const dialog = await getMatDialog(this.injector);
 *     dialog.open(SomeDialogComponent, { ... });
 *   }
 * }
 * ```
 */
export async function getMatDialog(injector: Injector) {
  const { MatLegacyDialogModule, MatLegacyDialog } = await import(
    /* webpackChunkName: 'material-legacy-dialog' */ '@angular/material/legacy-dialog'
  );

  const ngModuleRef: NgModuleRef<MatLegacyDialogModule> = createNgModule(
    MatLegacyDialogModule,
    injector,
  );

  return ngModuleRef.injector.get(MatLegacyDialog);
}
