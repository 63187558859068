<section class="support">
  <article class="contentWrapper">
    <h1 class="pageTitle">Support</h1>
    <div class="supportWrapper">
      <div>
        For support request select the button in the bottom righthand side of
        the screen and complete the form with the required information. We will
        be with you as soon as possible either via email, phone, or remote
        access.
      </div>
      <div>
        <a
          target="blank_"
          href="https://gpbrands.support/customer/Remote%20Support-windows64-online.exe?language=en&hostname=https%3A%2F%2Fgpbrands.support&ie=ie.exe"
          >One-Time Customer Access</a
        >
        <a
          target="blank_"
          href="https://gpbrands.support/access/Remote%20Access-windows64-online.exe?language=en&hostname=https%3A%2F%2Fgpbrands.support&ie=ie.exe"
          >Remote Access</a
        >
      </div>
    </div>
  </article>
</section>
