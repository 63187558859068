import {
  Routes,
  provideRouter,
  withDisabledInitialNavigation,
} from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { GPRatePro } from './public/gprateprodownload/gpratepro.component';
import { publicRoutes } from './public/public.routes';

const routes: Routes = [
  {
    path: 'owner',
    loadChildren: () => import('./owner/owner.module').then(m => m.OwnerModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () => publicRoutes,
  },
  {
    path: 'gpratepro',
    component: GPRatePro,
  },
  {
    path: '**',
    loadComponent: () =>
      import(
        /* webpackChunkName: 'not-found' */ './public/not-found/not-found.component'
      ).then(m => m.NotFoundComponent),
    // redirectTo: "/",
    pathMatch: 'full',
  },
];

export const provideAppRouting = () =>
  provideRouter(
    routes,
    // Disable initial sync navigation in router config and schedule it manually.
    withDisabledInitialNavigation(),
  );
