import { Action } from '@ngrx/store';

export enum EAuthActions {
  RefreshToken = '[Auth] Refresh Auth',
  RefreshTokenSuccess = '[Auth] Refresh Auth Success',
  SignIn = '[Auth] Sign In',
  SignInSuccess = '[Auth] Sign In Success',
  SignInFailure = '[Auth] Sign In Failure',
}

export class SignIn implements Action {
  public readonly type = EAuthActions.SignIn;
  constructor(public payload: any) {}
}

export class SignInSuccess implements Action {
  public readonly type = EAuthActions.SignInSuccess;
  constructor(public payload: any) {}
}

export class SignInFailure implements Action {
  public readonly type = EAuthActions.SignInFailure;

  constructor(public payload: any) {}
}

export type AuthActions = SignIn | SignInSuccess | SignInFailure;
