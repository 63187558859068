import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { first, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SwUpdateService {
  private readonly _router = inject(Router);
  private readonly _swUpdate = inject(SwUpdate);

  async waitForUpdate(): Promise<void> {
    if (typeof navigator === 'undefined' || !navigator.serviceWorker) {
      return;
    }

    const versionReady = this._swUpdate.versionUpdates.pipe(
      first(event => event.type === 'VERSION_READY'),
    );

    await firstValueFrom(versionReady);

    // If there's any new version available let's wait for the first navigation end event
    // to reload the page. We have to wait until the routing completely ends and the browser
    // history is updated with the browser URL.
    const firstNavigation = this._router.events.pipe(
      first(event => event instanceof NavigationEnd),
    );

    await firstValueFrom(firstNavigation);

    try {
      await this._unregisterAll();
    } finally {
      location.reload();
    }
  }

  private async _unregisterAll(): Promise<void> {
    const registrations = await navigator.serviceWorker.getRegistrations();

    await Promise.all(
      registrations.map(registration => registration.unregister()),
    );
  }
}
