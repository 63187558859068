import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { IAppState } from '../state/app.state';

import { authReducer } from './auth.reducer';
import { storesReducer } from './stores.reducers';
import { userReducer } from './user.reducers';

export const appReducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  user: userReducer,
  auth: authReducer,
  stores: storesReducer,
};
