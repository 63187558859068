import { AuthService } from 'src/app/services/auth.service';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  EAuthActions,
  SignIn,
  SignInFailure,
  SignInSuccess,
} from '../actions/auth.actions';
import { IAppState } from '../state/app.state';

@Injectable()
export class AuthEffects {
  signIn$: Observable<SignInSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<SignIn>(EAuthActions.SignIn),
      map((action: SignIn) => action.payload),
      switchMap((payload: any) => this._authService.login(payload)),
      switchMap((tokenPair: any) => {
        if (global_isBrowser) {
          localStorage.setItem('accessToken', tokenPair.accessToken);
        }
        this._router.navigate(['/owner/']);
        return of(new SignInSuccess(tokenPair));
      }),
      catchError((err, caught) => {
        this._store.dispatch(new SignInFailure(err));

        return caught;
      }),
    ),
  );

  // @Effect()
  // refreshToken$: Observable<GetUser> = this._actions$.pipe(
  //     ofType<RefreshToken>(EAuthActions.RefreshToken),
  //     switchMap(() => this._authService.refresh()),
  //     switchMap((tokenPair: any) => {
  //         localStorage.setItem('accessToken', tokenPair.accessToken)
  //         this._cookieService.set('refreshToken', tokenPair.refreshToken);
  //         return of(new GetUser());
  //     }),
  //     catchError((err, caught) => caught)
  // );

  constructor(
    private _actions$: Actions,
    private _authService: AuthService,
    private _router: Router,
    private _store: Store<IAppState>,
  ) {}
}
