import { mergeApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { appConfig } from './app.config';

export const appBrowserConfig = mergeApplicationConfig(appConfig, {
  providers: [
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
});
