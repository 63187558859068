import { ScriptsService } from 'src/app/services/scripts.service';

import { DOCUMENT } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { SwUpdateService } from './services/sw-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(router: Router, private _scriptsService: ScriptsService) {
    const document = inject(DOCUMENT);
    // The `document.URL` fallback is for pre-rendering because `pathname`
    // is always an empty string.
    const url = document.location.pathname || document.URL;

    if (global_isServer) {
      router.navigate([url]);
    } else {
      setTimeout(() => {
        router.navigate([url]);
      });
    }

    router.events.pipe(takeUntilDestroyed()).subscribe(val => {
      // see also
      if (val instanceof NavigationEnd) {
        if (!val.url.includes('/store/')) {
          this._scriptsService.setGTagScript(null);
          this._scriptsService.setGTagFrame(null);
        }
      }
    });

    if (global_isBrowser) {
      inject(SwUpdateService).waitForUpdate();
    }
  }
}
