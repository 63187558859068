import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(private titleService: Title, private meta: Meta) {}

  ngOnInit() {
    this.titleService.setTitle("Goin' Postal - Home");
    this.meta.updateTag({
      name: 'description',
      content:
        "Goin' Postal is your Friendly Neighborhood Shipping Center. Sending your FedEx, UPS, DHL, USPS shipments and more since 2002. Franchises available!",
    });

    // *****
    this.meta.updateTag({ property: 'og:type', content: 'business.business' });
    this.meta.updateTag({
      property: 'og:title',
      content: "Goin' Postal - Retail Shipping Franchise",
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://goinpostal.com/',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://goinpostal.com/assets/pictures/OG.png',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Goin' Postal is your Friendly Neighborhood Shipping Center. Trust us to send your FedEx, UPS, DHL, USPS shipments and more. Franchises available!",
    });

    this.meta.updateTag({
      property: 'business:contact_data:street_address',
      content: '4941 4th Street',
    });
    this.meta.updateTag({
      property: 'business:contact_data:locality',
      content: 'Zephyrhills',
    });
    this.meta.updateTag({
      property: 'business:contact_data:region',
      content: 'FL',
    });
    this.meta.updateTag({
      property: 'business:contact_data:postal_code',
      content: '33542',
    });
    this.meta.updateTag({
      property: 'business:contact_data:country_name',
      content: 'United States',
    });
    // *****
  }

  ngOnDestroy() {
    this.meta.removeTag("property='og:type'");
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:url'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:description'");

    this.meta.removeTag("name='description'");

    this.meta.removeTag("property='business:contact_data:street_address'");
    this.meta.removeTag("property='business:contact_data:locality'");
    this.meta.removeTag("property='business:contact_data:region'");
    this.meta.removeTag("property='business:contact_data:postal_code'");
    this.meta.removeTag("property='business:contact_data:country_name'");
  }
}
