import { Component, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  standalone: true,
})
export class SupportComponent implements OnDestroy {
  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'support');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'support');
  }
}
