import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl: string;

  constructor(private _cookieService: CookieService, private http: HttpClient) {
    this.apiUrl = environment.apiUrl + 'auth';
  }

  public login(payload: any) {
    return this.http.post(`${this.apiUrl}/login`, payload);
  }

  public checkEmail(data: string) {
    return this.http.post(`${this.apiUrl}/restore/password`, data);
  }

  public checkCode(code: string | number) {
    return this.http.post(`${this.apiUrl}/checkCode`, code);
  }

  public updatePassword(newPassword: string | number) {
    return this.http.put(`${this.apiUrl}/update/password`, newPassword);
  }
}
