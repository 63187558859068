import { bootstrapApplication } from '@angular/platform-browser';

import { appBrowserConfig } from './app/app.browser.config';
import { AppComponent } from './app/app.component';

async function bootstrap() {
  // When we're in development mode, let's measure how much does it take to bootstrap
  // the entire goinpostal app.
  if (ngDevMode) {
    performance.mark(/* markName */ 'goinpostal:bootstrap:start');
  }

  await bootstrapApplication(AppComponent, appBrowserConfig);

  if (ngDevMode) {
    performance.mark(/* markName */ 'goinpostal:bootstrap:end');
    performance.measure(
      /* measureName */ 'goinpostal:bootstrap',
      /* startMeasure */ 'goinpostal:bootstrap:start',
      /* stopMeasure */ 'goinpostal:bootstrap:end',
    );

    performance.clearMarks(/* markName */ 'goinpostal:bootstrap:start');
    performance.clearMarks(/* markName */ 'goinpostal:bootstrap:end');

    const entries = performance.getEntriesByName(
      'goinpostal:bootstrap',
      'measure',
    );
    if (entries.length > 0) {
      const [entry] = entries;
      console.log(
        `%cℹ️ Bootstrap duration: ${Number(entry.duration).toFixed(4)}`,
        'color: #7b68ee',
      );
    }
  }
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
