import { Action } from '@ngrx/store';

export enum EStoresActions {
  GetStore = '[Stores] Get Store',
  GetStoreSuccess = '[Stores] Get Store Success',
  GetStores = '[Stores] Get Stores',
  GetStoresSuccess = '[Stores] Get Stores Success',
}

export class GetStore implements Action {
  public readonly type = EStoresActions.GetStore;
  constructor(public payload: any) {}
}

export class GetStoreSuccess implements Action {
  public readonly type = EStoresActions.GetStoreSuccess;
  constructor(public payload: any) {}
}

export class GetStores implements Action {
  public readonly type = EStoresActions.GetStores;
  constructor(public payload: any) {}
}

export class GetStoresSuccess implements Action {
  public readonly type = EStoresActions.GetStoresSuccess;
  constructor(public payload: any) {}
}

export type StoriesActions =
  | GetStore
  | GetStoreSuccess
  | GetStores
  | GetStoresSuccess;
