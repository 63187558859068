import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterComponent } from '../shared/footer/footer.component';
import { HeaderComponent } from '../shared/header/header.component';

@Component({
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  standalone: true,
  imports: [RouterModule, HeaderComponent, FooterComponent],
})
export class PublicComponent {}
