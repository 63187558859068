import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptsService {
  private readonly _document = inject(DOCUMENT);

  setGTagScript(tag: string | null) {
    const id = 'gtm';
    const existingScript = this._document.getElementById(id);
    if (existingScript) {
      existingScript.remove();
    }

    if (tag) {
      const script = this._document.createElement('script');
      script.id = id;
      script.type = 'text/partytown'; // Required
      script.textContent = `
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "${tag}");
        `;
      this._document.head.appendChild(script);
    }
  }

  setGTagFrame(tag: string | null) {
    const id = 'gtm-iframe';
    const existingIframe = this._document.getElementById(id);
    if (existingIframe) {
      existingIframe.remove();
    }

    if (tag) {
      const noscript = this._document.createElement('noscript');
      noscript.id = id;

      const iframe = this._document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${tag}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      noscript.appendChild(iframe);
      this._document.body.appendChild(noscript);
    }
  }
}
