<header class="header">
  <article class="contentWrapper">
    <div class="logo">
      <a [routerLink]="[logged ? '/owner' : '/']">
        <img
          width="250"
          height="52"
          src="/assets/logos/logo.webp"
          alt="GoinPostal Main Logo"
        />
      </a>
    </div>
    <nav class="headerNav">
      <a routerLink="/locations" mat-button color="primary">
        <div class="nav_item">
          <div class="icon">
            <img src="/assets/icons/location_ico.webp" alt="location icon" />
          </div>
          Locations
        </div>
      </a>

      <a routerLink="/services" mat-button color="primary">
        <div class="nav_item">
          <div class="icon">
            <img src="/assets/icons/service_ico.webp" alt="service icon" />
          </div>
          Services
        </div>
      </a>

      <a routerLink="/about-us" mat-button color="primary">
        <div class="nav_item">
          <div class="icon">
            <img src="/assets/icons/about_us_ico.webp" alt="about us icon" />
          </div>
          About Us
        </div>
      </a>

      <a routerLink="/franchise-info" mat-button color="primary">
        <div class="nav_item">
          <div class="icon">
            <img src="/assets/icons/franchise_ico.webp" alt="franchise icon" />
          </div>
          Franchise Info
        </div>
      </a>
    </nav>
    <nav class="mobileMenu">
      <button mat-button [matMenuTriggerFor]="menu" aria-label="Menu">
        <span
          ><img
            src="/assets/icons/menuIcon.svg"
            width="30"
            height="30"
            alt="menu icon"
        /></span>
      </button>
      <mat-menu #menu="matMenu">
        <div class="sidenav_logo">
          <img
            src="/assets/icons/logo-layout-cash.webp"
            alt="layout cash logo"
          />
        </div>

        <a routerLink="/locations" mat-menu-item>
          <div class="nav_item">
            <div class="icon">
              <img src="/assets/icons/location_ico.webp" alt="location icon" />
            </div>
            <span> Locations</span>
          </div>
        </a>
        <a routerLink="/services" mat-menu-item>
          <div class="nav_item">
            <div class="icon">
              <img src="/assets/icons/service_ico.webp" alt="service icon" />
            </div>
            <span> Services</span>
          </div>
        </a>
        <a routerLink="/about-us" mat-menu-item>
          <div class="nav_item">
            <div class="icon">
              <img src="/assets/icons/about_us_ico.webp" alt="about us icon" />
            </div>
            <span> About Us</span>
          </div>
        </a>
        <a routerLink="/franchise-info" mat-menu-item>
          <div class="nav_item">
            <div class="icon">
              <img
                src="/assets/icons/franchise_ico.webp"
                alt="franchise icon"
              />
            </div>
            <span> Franchise Info</span>
          </div>
        </a>
        <a role="button" tabindex="0" (click)="login()" mat-menu-item>
          <div class="nav_item">
            <div class="icon">
              <img
                src="/assets/icons/Passport_Photos.webp"
                alt="franchise icon"
              />
            </div>
            <span>LOGIN</span>
          </div>
        </a>
      </mat-menu>
    </nav>
  </article>
</header>

<header class="subHeader">
  <article class="contentWrapper">
    <div class="tabs_content">
      <mat-tab-group animationDuration="0ms" dynamicHeight>
        <mat-tab label="First" dynamicHeight>
          <ng-template mat-tab-label>
            <div class="icon">
              <img
                class="icon_active"
                src="/assets/icons/track_ico_white.svg"
                width="35"
                height="35"
                alt="track icon active"
              />
              <img
                class="icon_noactive"
                src="/assets/icons/track_ico.svg"
                width="35"
                height="35"
                alt="track icon inactive"
              />
            </div>
            Track Package
          </ng-template>

          <mat-form-field floatLabel="never">
            <input
              placeholder="Enter your tracking number here"
              matInput
              [(ngModel)]="trackingId"
            />
            <button
              [routerLink]="['/tracking/', trackingId]"
              mat-icon-button
              color="primary"
              type="submit"
              aria-label="Search by tracking number"
            >
              <mat-icon svgIcon="search"></mat-icon>
            </button>
          </mat-form-field>
        </mat-tab>

        <mat-tab label="Second">
          <ng-template mat-tab-label>
            <div class="icon">
              <img
                class="icon_active"
                src="/assets/icons/storage_ico_white.svg"
                width="35"
                height="35"
                alt="storage icon active"
              />
              <img
                class="icon_noactive"
                src="/assets/icons/storage_ico.svg"
                width="35"
                height="35"
                alt="storage icon inactive"
              />
            </div>
            Search Store
          </ng-template>

          <form [formGroup]="findNearestForm">
            <mat-form-field floatLabel="never" class="select">
              <mat-select
                placeholder="Nearest"
                disableOptionCentering
                panelClass="myPanelClass"
                formControlName="location"
              >
                <mat-option [value]="1000000"> Nearest </mat-option>
                <mat-option [value]="25"> 25 miles </mat-option>
                <mat-option [value]="50"> 50 miles </mat-option>
                <mat-option [value]="100"> 100 miles </mat-option>
                <mat-option [value]="200"> 200 miles </mat-option>
                <mat-option [value]="100000"> Anywhere </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              class="search"
              (click)="changeDistance(findNearestForm)"
              mat-icon-button
              color="primary"
              type="submit"
              aria-label="Search near location"
            >
              <mat-icon svgIcon="search"></mat-icon>
            </button>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="main_content">
      <mat-card class="tracking" (keydown.enter)="trackPackage(trackingId)">
        <div class="label">Track your package</div>
        <div class="input_block">
          <img
            src="/assets/icons/track_ico.svg"
            width="50"
            height="50"
            alt="track icon"
          />

          <mat-form-field floatLabel="never">
            <input
              placeholder="Enter your tracking number here"
              matInput
              [(ngModel)]="trackingId"
            />
            <button
              class="search"
              [routerLink]="['/tracking/', trackingId]"
              mat-icon-button
              color="primary"
              type="submit"
              aria-label="Search by tracking number"
            >
              <mat-icon svgIcon="search"></mat-icon>
            </button>
          </mat-form-field>
        </div>
      </mat-card>

      <mat-card class="closest">
        <div class="label">Search for a store close to you</div>

        <div class="input_block">
          <img
            src="/assets/icons/storage_ico.svg"
            width="50"
            height="50"
            alt="storage icon"
          />

          <form [formGroup]="findNearestForm">
            <mat-form-field floatLabel="never" class="select">
              <mat-select
                placeholder="Nearest"
                disableOptionCentering
                panelClass="myPanelClass"
                formControlName="location"
              >
                <mat-option [value]="1000000"> Nearest </mat-option>
                <mat-option [value]="25"> 25 miles </mat-option>
                <mat-option [value]="50"> 50 miles </mat-option>
                <mat-option [value]="100"> 100 miles </mat-option>
                <mat-option [value]="200"> 200 miles </mat-option>
                <mat-option [value]="100000"> Anywhere </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              class="search"
              (click)="changeDistance(findNearestForm)"
              mat-icon-button
              color="primary"
              type="submit"
              aria-label="Search near location"
            >
              <mat-icon svgIcon="search"></mat-icon>
            </button>
          </form>
        </div>
      </mat-card>
    </div>
  </article>
</header>
